import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDsTcfcXeWN6tLZPwy7nGZiUYTW5KpEohk",
  authDomain: "zestoepsel-722c5.firebaseapp.com",
  projectId: "zestoepsel-722c5",
  storageBucket: "zestoepsel-722c5.firebasestorage.app",
  messagingSenderId: "368932668075",
  appId: "1:368932668075:web:a7f9cd4bec15d28ecc6dee",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logoutButton = document.getElementById("logout");
const loginForm = document.getElementById("login");
const dataOutput = document.getElementById("dataOutput");
const search = document.getElementById("search");
const select = document.getElementById("select");

auth.onAuthStateChanged((user) => {
  if (user) {
    console.log("the user signed in", user);

    search.style.display = "block";
    loginForm.style.display = "none";
    logoutButton.style.display = "block";
  }

  if (!user) {
    console.log("the user signed out");

    loginForm.style.display = "block";
    search.style.display = "none";
    logoutButton.style.display = "none";

    dataOutput.innerHTML = "";
  }
});

search.addEventListener("submit", async (e) => {
  e.preventDefault();

  // console.log(select.value, search.searchB.value);
  let a = [];
  dataOutput.innerHTML = "";

  const querySnapshot = await getDocs(
    query(
      collection(db, "test"),
      where(select.value, "==", search.searchB.value)
    )
  );

  querySnapshot.forEach((doc) => {
    a.push(doc.data());
  });

  // console.log(a);

  if (a.length == 0) {
    dataOutput.innerHTML += `
      <div>
      <p> no result found </p>
      </div>
    `;
  } else {
    a.map((value, index) => {
      console.log(index, value);

      dataOutput.innerHTML += `
        <div id="list-${index}">
        <p> ${value.firstname} </p>
        <p> ${value.lastname} </p>
        <p> ${value.email} </p>
        <p> ${value.phone} </p>
        <p> ${value.info} </p>
        </div>
      `;
    });
  }
});

logoutButton.addEventListener("click", () => {
  signOut(auth)
    .then(() => {
      console.log("the user signed out");
    })
    .catch((err) => {
      console.log(err.message);
    });
});

loginForm.addEventListener("submit", (e) => {
  e.preventDefault();

  const email = loginForm.email.value;
  const password = loginForm.password.value;

  signInWithEmailAndPassword(auth, email, password)
    .then((cred) => {
      console.log("user logged in:", cred.user.uid);
    })
    .catch((err) => {
      console.log(err.message);
    });
});
